<script lang="ts" setup>
import type { Advantage } from '~/types/advantages'

const router = useRouter()

const props = defineProps<{
  advantage: Advantage
}>()

async function goToPath (): Promise<void> {
  const { path } = props

  if (!path) {
    return
  }

  await router.push(path)
}
</script>

<template>
  <div
    class="rounded-2xl bg-white p-3 md:rounded-3xl md:p-5"
    :class="{ 'cursor-pointer': advantage.path }"
    @click="goToPath"
  >
    <div class="mb-2 flex items-center">
      <div
        class="mr-2 flex size-8 items-center justify-center rounded-lg border border-solid border-[#f8f8f8] bg-[#f1f1f1]"
      >
        <SvgIcon class="text-2xl text-main-green" :name="advantage.iconName" />
      </div>
      <div class="text-[18px] font-medium md:text-[22px]">
        {{ advantage.title }}
      </div>
    </div>

    <div class="text-sm text-[#7b7b7b] md:text-base">
      {{ advantage.description }}
    </div>
  </div>
</template>
